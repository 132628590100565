//
//
//
//
//
//
//

import WidgetPanel from "./WidgetPanel";
import _disableOutline from "../../mixins/_disableOutline";
export default {
	name: "Widget",
	mixins: [_disableOutline],
	props: {
		widgetID: {
			type: [String, Number],
			default() {
				return 0
			}
		},
		schoolID: {
			type: [String, Number],
			default() {
				return 0
			}
		},
	},
	components: {WidgetPanel},
	data() {
		return {
			widgetConfig: {},
			definitions: {}
		}
	},
	methods: {
		getWidgetConfig() {
			this.$axios.get('widget', {
				params: {
					widgetID: +this.widgetID,
					schoolID: +this.schoolID
				}
			}).then((res) => {
				this.widgetConfig = res.data.data;
			}).catch((err) => {
				console.dir(err);
			});
		},
		getDefinitions() {
			this.$axios.get('definitions').then((res) => {
				this.definitions = res.data;
			}).catch((err) => {
				console.dir(err);
			});
		},
	},
	computed: {
		fonts() {
			return this.definitions.DEFWidgetFont ? Object.values(this.definitions.DEFWidgetFont) : []
		}
	},
	beforeMount() {
		this.getWidgetConfig();
		this.getDefinitions();
	},
	beforeDestroy() {}
}
