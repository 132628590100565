//
//
//
//
//
//


import Widget from "./components/Widget/Widget";

export default {
	name: 'App',
	props: {
		widgetID: {
			type: [String, Number],
			default() {
				return 0
			}
		},
		schoolID: {
			type: [String, Number],
			default() {
				return 0
			}
		},
	},
	components: {Widget},
}
