//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseInput from "../Base/BaseInput";

export default {
	name: "WidgetForm",
	props: {
		widget: {
			type: Object,
			default() {
				return {}
			}
		},
		fonts: {
			type: Array,
			default() {
				return []
			}
		},
	},
	components: {BaseInput},
	data() {
		return {
			loading: false,
			formData: {
				widgetID: +this.widget.widgetID,
				schoolID: +this.widget.schoolID,
				email: '',
				name: '',
				phone: '',
			},
			formError: {
				email: '',
				name: '',
				phone: '',
			}
		}
	},
	methods: {
		formatPrice(str) {
			let parts = (str + '').split('.');
			let main = parts[0];
			let output = '';
			for (let i = main.length - 1; i >= 0; i--) {
				output = main.charAt(i) + output;
				if ((main.length - i) % 3 === 0 && i > 0) {
					output = '\u00A0' + output;
				}
			}
			if (parts.length > 1) {
				output += '.' + parts[1];
			}
			return output;
		},
		getUtmData() {
			let str = window.location.search.slice(1);
			str.split('&').map((key) => {
				let rowArr = key.split('=');
				this.formData[rowArr[0]] = decodeURIComponent(rowArr[1])
			});
		},
		onBuyClick() {
			this.resetErrors();
			this.loading = true;
			this.$axios.post('order', {
				...this.formData
			}).then((res) => {
				this.loading = false;
				if (res.data.url) {
					window.location.href = res.data.url;
				}
			}).catch((err) => {
				console.dir(err);
				if (err.response.data && err.response.data.errors) {
					this.showErrors(err.response.data.errors)
				}
				this.loading = false;
			});
		},
		resetErrors() {
			this.formError = {
				email: '',
				name: '',
				phone: '',
			}
		},
		showErrors(errors) {
			for (let field in errors) {
				if (this.formError.hasOwnProperty(field) && errors.hasOwnProperty(field)) {
					this.formError[field] = errors[field][0];
				}
			}
		}
	},
	computed: {
		price() {
			if (this.widget.productOffer_detail) {
				if (Math.ceil(this.widget.productOffer_detail.oncePrice)) {
					return this.formatPrice(Math.ceil(this.widget.productOffer_detail.oncePrice));
				} else if (Math.ceil(this.widget.productOffer_detail.subscriptionFirstPrice)) {
					return this.formatPrice(Math.ceil(this.widget.productOffer_detail.subscriptionFirstPrice));
				}
			} else {
				return 0;
			}
		},
		widgetStyle() {
			let font = this.fonts.find(({id}) => id === this.widget.widgetFontID);
			if (font) {
				return `font-family: ${font.title}, 'sans-serif'`
			}
		},
		titleStyle() {
			let font = this.fonts.find(({id}) => id === this.widget.widgetTitleFontID);
			if (font) {
				return `color: ${this.widget.widgetTitleColor}; font-family: ${font.title}, 'sans-serif'`
			} else {
				return `color: ${this.widget.widgetTitleColor};`
			}
		},
		descriptionStyle() {
			let font = this.fonts.find(({id}) => id === this.widget.widgetDescriptionFontID);
			if (font) {
				return `color: ${this.widget.widgetDescriptionColor}; font-family: ${font.title}, 'sans-serif'`
			} else {
				return `color: ${this.widget.widgetDescriptionColor};`
			}
		},
		hintStyle() {
			let font = this.fonts.find(({id}) => id === this.widget.hintFontID);
			if (font) {
				return `color: ${this.widget.hintColor}; font-family: ${font.title}, 'sans-serif'`
			} else {
				return `color: ${this.widget.hintColor};`
			}
		},
		btnStyle() {
			let font = this.fonts.find(({id}) => id === this.widget.buttonFontID);
			if (font) {
				return `color: ${this.widget.buttonTextColor}; background-color: ${this.widget.buttonColor}; font-family: ${font.title}, 'sans-serif'`
			} else {
				return `color: ${this.widget.buttonTextColor}; background-color: ${this.widget.buttonColor};`
			}
		},
	},
	mounted() {
		if (window.location.search.indexOf('utm_') !== -1) {
			this.getUtmData();
		}
	},
	beforeDestroy() {
	}
}
