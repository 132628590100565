import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import './assets/scss/style.scss'


Vue.config.productionTip = false;

// axios settings
Vue.prototype.$axios = axios;
Vue.prototype.$axios.defaults.baseURL = process.env.API_WEB_URL;

const widgets = document.querySelectorAll('.ocdWidget');

widgets.forEach((widget) => {
	new Vue({
		render: h => h(App, {
			props: {
				widgetID: widget.getAttribute('data-widget-id'),
				schoolID: widget.getAttribute('data-school-id'),
			}
		}),
	}).$mount(widget);
});
