//
//
//
//
//
//
//

import WidgetForm from "./WidgetForm";
export default {
	name: "WidgetPanel",
	props: {
		widget: {
			type: Object,
			default() {
				return {}
			}
		},
		fonts: {
			type: Array,
			default() {
				return []
			}
		},
	},
	components: {WidgetForm},
	data() {
		return {}
	},
	methods: {},
	computed: {},
	mounted() {},
	beforeDestroy() {}
}
