//
//
//
//
//
//
//
//
//
//

export default {
	name: "BaseInput",
	components: {},
	props: {
		label: {
			type: String,
			default() {
				return ''
			}
		},
		error: {
			type: String,
			default() {
				return ''
			}
		},
		value: {
			type: [Number, String],
			default() {
				return ''
			}
		},
	},
	data() {
		return {
			isErrorShow: false
		}
	},
	computed: {
		innerValue: {
			get() {
				return `${this.value}` || '';
			},
			set(val) {
				this.$emit('input', val || '')
			},
		}
	},
	methods: {
		checkInput() {
			if (this.$refs.input && this.$refs.input.value === '' && this.$refs.input !== document.activeElement) {
				this.removeActiveClass();
			} else {
				this.addActiveClass();
			}
		},
		addActiveClass() {
			this.$refs.input.closest('.ocd_widget_formField').classList.add('active');
		},
		removeActiveClass() {
			this.$refs.input.closest('.ocd_widget_formField').classList.remove('active');
		},
		onAnimationStart(e) {
			switch (e.animationName) {
				case 'onAutoFillStart':
					return this.checkInput(e);

				case 'onAutoFillCancel':
					return this.checkInput(e);
			}
		},
	},
	mounted() {
		this.checkInput();
		this.$refs.input.addEventListener('focus', this.addActiveClass);
		this.$refs.input.addEventListener('blur', this.checkInput);
		this.$refs.input.addEventListener('animationstart', this.onAnimationStart);
	},
	beforeDestroy() {
		this.$refs.input.removeEventListener('focus', this.addActiveClass);
		this.$refs.input.removeEventListener('blur', this.checkInput);
		this.$refs.input.removeEventListener('animationstart', this.onAnimationStart);
	},
}
